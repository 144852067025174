import React, { useContext, useEffect, useState } from "react";
import AlarmListItem from "./AlarmListItem";
import CheckboxCircle from "./UI/CheckboxCircle";
import {
  FaCheck,
  FaCross,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { FaX } from "react-icons/fa6";
import * as api from "apis/FusionAPI";
import useTextToSpeech from "hooks/useTextToSpeech";
import { useAPIData } from "hooks/useAPIData";
import { ColorContext } from "App";
import { TimeSinceDateTimeString } from "utils/stringUtils";

const Pagination = ({ limit, setLimit, totalCount, setOffset }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalCount / limit);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setOffset(limit * (page - 1));
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setCurrentPage(1);
    setOffset(0);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={{
          background: "none",
          border: "none",
          cursor: currentPage === 1 ? "default" : "pointer",
        }}
      >
        <FaChevronLeft color={currentPage === 1 ? "grey" : "white"} size={20} />
      </button>
      <span
        style={{ margin: "0 10px" }}
      >{`Page ${currentPage} of ${totalPages}`}</span>
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{
          background: "none",
          border: "none",
          cursor: currentPage === totalPages ? "default" : "pointer",
        }}
      >
        <FaChevronRight
          color={currentPage === totalPages ? "grey" : "white"}
          size={20}
        />
      </button>

      <p style={{ marginLeft: "10px" }}>Limit</p>
      <select
        onChange={handleLimitChange}
        value={limit}
        style={{ marginLeft: "10px", backgroundColor: "#436C9A" }}
      >
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </select>
    </div>
  );
};

export default function AlarmList({
  offset,
  setOffset,
  limit,
  setLimit,
  search,
  totalCount,
  setTotalCount,
  alarmData,
  setAlarmData,
  type,
}) {
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedAll, setSelectedAll] = useState(false);
  const [listOfSilences, setListOfSilences] = useState([]);
  const tts = useTextToSpeech();

  const [freshData, setFreshData] = useState([]);

  const theme = useContext(ColorContext);

  useEffect(() => {
    setFreshData(alarmData);
  }, [alarmData]);

  function selectAll(value) {
    let alarmIds = alarmData.map((alarm) => alarm.id);
    let newSelectedItems = {};
    alarmIds.forEach((id) => {
      newSelectedItems[id] = value;
    });
    setSelectedItems(newSelectedItems);
  }

  const silenceSelected2 = async () => {
    try {
      // Get fresh data
      let res;
      if (type === "pending") {
        res = await api.getPendingAlarmsPaginated(limit, offset, search);
      } else {
        res = await api.getAlarmHistoryPaginated(limit, offset, search);
      }

      const newData = res.data.data.map((alarm) => {
        const time = TimeSinceDateTimeString(new Date(alarm.activity));
        return { ...alarm, status: time, info: alarm.info };
      });

      setTotalCount(res.data.totalCount);
      setAlarmData(newData);

      let successfulSilences = [];

      for (let id in selectedItems) {
        if (selectedItems[id]) {
          const currDataOfThisAlarm = freshData.find(
            (alarm) => alarm.id === parseInt(id)
          );
          if (currDataOfThisAlarm.silenced) {
            // Already silenced
            continue;
          } else {
            const res = await api.silenceAlarm(id);
            if (res.status === 200) {
              successfulSilences.push(id);
            }
          }
        }
      }

      // Update the alarm list
      const updatedAlarms = alarmData.map((alarm) => {
        if (successfulSilences.includes(alarm.id.toString())) {
          return { ...alarm, silenced: true };
        }
        return alarm;
      });

      setAlarmData(updatedAlarms);
      setSelectedAll(false);
      setSelectedItems({});
    } catch (error) {
      console.error("Error silencing alarms:", error);
    }
  };

  const acknowledgeSelected = async () => {
    let successfulSilences = [];
    const promises = [];

    for (let id in selectedItems) {
      if (selectedItems[id]) {
        const promise = api.acknowledgeAlarm(id).then((res) => {
          const currDataOfThisAlarm = freshData.find(
            (alarm) => alarm.id === parseInt(id)
          );
          if (currDataOfThisAlarm.silenced) {
            // Already silenced
          } else {
            return api.silenceAlarm(id).then((res) => {
              if (res.status === 200) {
                successfulSilences.push(id);
              }
            });
          }
        });
        promises.push(promise);
      }
    }

    await Promise.all(promises);

    // Update the alarm list
    const updatedAlarms = alarmData.map((alarm) => {
      if (successfulSilences.includes(alarm.id.toString())) {
        return { ...alarm, silenced: true };
      } else {
      }
      return alarm;
    });

    setAlarmData(updatedAlarms);
    setSelectedAll(false);
    setSelectedItems({});
  };
  if (freshData.length === 0) {
    return "All clear, there are currently no pending alarms at this time.";
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "90%",
        boxSizing: "border-box",
      }}
    >
      <div>
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          {type !== "history" && (
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
              <CheckboxCircle
                value={selectedAll}
                callback={(e) => {
                  setSelectedAll(!selectedAll);
                  selectAll(!selectedAll);
                }}
              />
              Select All
            </div>
          )}

          {type !== "history" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                acknowledgeSelected();
              }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 1000,
                  cursor: "pointer",
                }}
              >
                <FaCheck
                  size={22}
                  style={{
                    color: "black",
                    margin: "4px",
                  }}
                />
              </div>
              Acknowledge
            </div>
          )}
          {type !== "history" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                silenceSelected2();
              }}
            >
              <div
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: theme.primaryHighlight,
                  borderRadius: 1000,
                  cursor: "pointer",
                }}
              >
                <FaX
                  size={20}
                  style={{
                    color: "black",
                    margin: "5px",
                  }}
                />
              </div>
              Silence
            </div>
          )}

          <div>
            <Pagination
              limit={limit}
              setLimit={setLimit}
              totalCount={totalCount}
              setOffset={setOffset}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <div style={{ flex: 1 }}>Current State</div>
          <div style={{ flex: 1 }}>Source</div>
          <div style={{ flex: 2 }}>Device</div>
          <div style={{ flex: 1 }}>Type</div>
          <div style={{ flex: 1 }}>Activity</div>
          <div style={{ flex: 1 }}>Status</div>
          <div style={{ flex: 0.5 }}></div>
        </div>
      </div>

      <div style={{ overflow: "auto" }}>
        {freshData.map((alarm, index) => {
          return (
            <div>
              <AlarmListItem
                key={index}
                data={alarm}
                style={{
                  backgroundColor: index % 2 === 0 ? theme.base : "#2C373B",
                }}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                listOfSilences={listOfSilences}
                setAlarmData={setAlarmData}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
