import { Slider } from "antd";
import { ColorContext } from "App";
import { usePerms } from "hooks/usePerms";
import React, { useContext, useEffect, useRef, useState } from "react";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import aware from "assets/icons/AWAREICON.png";
import default1 from "assets/icons/Default System Icon 1.png";
import default2 from "assets/icons/Default System Icon 2.png";
import background from "assets/icons/Default System Icon Background.png";

const iconMap = {
  AWARE: aware,
  "Fusion User": default1,
  "global-actions": default1,
  "Overwatch AI": default2,
  Other: background,
};

/**
 * A row view displaying information and controls for alarms.
 * @param {{ alarm: {index: number, ackowledged: *[], activity: string, ca_identifier: string, cw_identifier: string, device: string, expire_time: string, ga_identifier: string, headline: string, id: number, info: Object, message: string, organization_id: number, silenced: boolean, silenced_by: (string | number | null), source: string, state: boolean}}}
 * @returns
 */
export default function AlarmContentRow({ alarm }) {
  const theme = useContext(ColorContext);
  const [timeSinceActivity, setTimeSinceActivity] = useState("");
  const [headlineShort, setHeadlineShort] = useState("");
  const perms = usePerms();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const msSinceActivity = Date.now() - new Date(alarm.activity);
    const seconds = Math.floor(msSinceActivity / 1000) % 60;
    const minutes = Math.floor(msSinceActivity / 1000 / 60) % 60;
    const hours = Math.floor(msSinceActivity / 1000 / 60 / 60) % 24;
    const days = Math.floor(msSinceActivity / 1000 / 60 / 60 / 24);

    setTimeSinceActivity(`
    ${days > 0 ? `${days} Days` : ""}\t
    ${hours >= 10 ? `${hours}` : `0${hours}`}:
    ${minutes >= 10 ? `${minutes}` : `0${minutes}`}:
    ${seconds >= 10 ? `${seconds}` : `0${seconds}`} hours
    `);

    const prestring = alarm.headline;
    setHeadlineShort(alarm.headline.split(";")[0]);
    alarm.headline = prestring;
  }, [alarm]);

  const keySort = (a, b) => {
    const keys = [
      "type",
      "startTime",
      "expireTime",
      "location",
      "lat",
      "lon",
      "nwsoffice",
      "alarm_id",
    ];

    for (const key of keys) {
      if (a.toLowerCase() === key) return -1;
      if (b.toLowerCase() === key) return 1;
    }

    return a.localeCompare(b);
  };

  const parseKey = (key) => {
    switch (key.toLowerCase()) {
      case "type":
        return "Alert Type";
      case "nwsoffice":
        return "Issuing Office";
      case "starttime":
        return "Start Time";
      case "expiretime":
        return "Expiration Time";
      case "severity":
        return "Severity";
      case "timezone":
        return "Time Zone";
      case "latitude":
        return "Latitude";
      case "longitude":
        return "Longitude";
      case "location":
        return "Location";
      default:
        return "";
    }
  };

  const getAlarmId = () => {
    if (alarm.ga_identifier) return `GA_${alarm.ga_identifier}`;
    else if (alarm.ca_identifier) return `CA_${alarm.ca_identifier}`;
    else if (alarm.cw_identifier) return `CW_${alarm.cw_identifier}`;
    else return `ID_${alarm.id}`;
  };

  const sourceStringCorrection = (str) => {
    return str.replace("global-actions", "Global Action");
  };

  const getKeyValue = (key) => {
    if (key.toLowerCase() !== "severity")
      return `${parseKey(key)}: ${alarm.info[key]}`;

    return (
      <div className='content-row body slider'>
        <div
          className='content-row body slider-text'
          style={{ backgroundColor: alertColorScale(alarm.info[key]) }}
        >
          {parseKey(key)}: {getSeverityLevel(alarm.info[key])}
        </div>
        <div
          style={{
            backgroundColor: theme.base,
            width: 200,
            marginLeft: 10,
            borderRadius: 5,
          }}
        >
          <Slider
            min={0}
            max={100}
            defaultValue={100 - alarm.info[key]}
            disabled
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`content-row root ${expanded ? "expanded" : "collapsed"}`}
      style={{
        "--color": expanded
          ? theme.primaryHighlight
          : alarm.index % 2 === 0
          ? theme.base
          : "#2C373B",
      }}
      onClick={() => setExpanded(!expanded)}
    >
      <div className='content-row header'>
        <div className='content-row section'>
          {alarm.state ? "Active" : "Inactive"}
        </div>
        <div className='content-row section'>
          <div
            className='icon-back-circle'
            style={{ "--color": theme.primaryShadow }}
          >
            <img src={iconMap[alarm.source]} alt={"source-icon"} size={25} />
          </div>
          {sourceStringCorrection(alarm.source)}
        </div>
        <div className='content-row section'>{alarm.device}</div>
        <div className='content-row section'>{headlineShort}</div>
        <div className='content-row section'>
          {new Date(alarm.activity).toLocaleString()}
        </div>
        <div className='content-row section'>{timeSinceActivity}</div>
        <div className='content-row section'>Controls</div>
      </div>
      {expanded && (
        <div className='content-row body'>
          <div className='content-row body headline'>{alarm.headline}</div>
          <div className='content-row body message'>
            {alarm.message
              .trim()
              .split("*")
              .map((line, index) => {
                if (line) return <li key={index}>{line}</li>;
                return <></>;
              })}
          </div>
          <div
            className='content-row body info-grid'
            style={{
              "--grid-size": `${Array(
                Math.floor(Math.sqrt(Object.keys(alarm.info).length))
              )
                .fill("1fr")
                .join(" ")}`,
            }}
          >
            {Object.keys(alarm.info)
              .filter(
                (key) =>
                  !["timezone", "popaffected"].includes(key.toLowerCase()) //from what I can tell, we ignore the timezone and popaffected keys
              )
              .sort(keySort)
              .map((key) => (
                <div key={key}>{getKeyValue(key)}</div>
              ))}
            <div>{`Alarm ID: ${getAlarmId()}`}</div>
          </div>
        </div>
      )}
    </div>
  );
}
