import React, { useContext, useState } from "react";
import "../AlarmHistory.css";
import { ColorContext } from "App";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { LIMIT_OPTIONS } from "hooks/useAlarms";

/**
 * A wrapper for alarms page content. Meant for Pending Alarms and Alarm History.
 * @param {{children: *, title: string, pageIndex: number, maxPageIndex: number, headerFields: string[], onPageChanged: (page: number), onLimitChanged: (limit: number), onSearchChanged: (search: string)}}
 */
export default function AlarmsContentWrapper({
  children,
  title,
  pageIndex,
  maxPageIndex,
  headerFields,
  onPageChanged,
  onLimitChanged,
  onSearchChanged,
}) {
  const theme = useContext(ColorContext);

  const setPageIndex = (newPageIndex) => {
    newPageIndex = Math.max(0, Math.min(maxPageIndex, newPageIndex)); //insure that new index is within range
    onPageChanged(newPageIndex);
  };

  //We don't actually need to store the limit in a state. The <input> does it for us.
  const setLimit = (newLimitOption) => {
    onLimitChanged(LIMIT_OPTIONS[newLimitOption]);
  };

  return (
    <div className='root'>
      {/* Header */}
      <div className='controls-container'>
        <div className='full-width'>
          <div className='title'>{title}</div>

          {/* Controls beneath title */}
          <div className='control-parent'>
            {/* Page number/swtich control */}
            {maxPageIndex > 1 && (
              <div className='control-parent no-width'>
                <button onClick={() => setPageIndex(pageIndex - 1)}>
                  <FaChevronLeft
                    color={pageIndex === 0 ? "grey" : "white"}
                    size={20}
                  />
                </button>
                <div>{`Page ${pageIndex + 1} of ${maxPageIndex + 1}`}</div>
                <button onClick={() => setPageIndex(pageIndex + 1)}>
                  <FaChevronRight
                    color={pageIndex >= maxPageIndex ? "grey" : "white"}
                    size={20}
                  />
                </button>
              </div>
            )}

            {/* Limit selector */}
            <div className='control-parent no-width'>
              <div>Limit</div>
              <select
                className='selector'
                onChange={(e) => setLimit(e.target.value)}
              >
                {LIMIT_OPTIONS.map((option, index) => (
                  <option value={index}>{option}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Searchbox */}
        <input
          className='searchbox'
          style={{ "--background-color": theme.primaryHighlight }}
          type='text'
          placeholder='Search...'
          onChange={(e) => onSearchChanged(e.target.value)}
        />
      </div>

      {/* Content header */}
      <div className='content-header'>
        {headerFields &&
          headerFields.map((header) => (
            <div className='content-row section'>{header}</div>
          ))}
      </div>

      {/* Content */}
      <div className='content-container'>{children}</div>
    </div>
  );
}
