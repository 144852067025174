import useTextToSpeech from "hooks/useTextToSpeech";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import * as api from "../apis/FusionAPI";

export default function AlarmListener({ isAuthenticated }) {
  const [receivedData, setReceivedData] = useState([]);
  const tts = useTextToSpeech();


  useEffect(() => {
    const res = api.getActiveAlarms().then((res) => {
      setReceivedData(res.data.result);
    });

    const interval = setInterval(() => {
      if (isAuthenticated) {
        const res = api.getActiveAlarms().then((res) => {
          setReceivedData(res.data.result);
        });
      } else {
        clearInterval(interval);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const runAlarms2 = () => {
    if (tts.isSpeaking() || tts.isPending()) {
      return;
    }

    let readAlarmsList = [
      {
        text: "Pending Alarms",
        id: -1,
        index: -1,
        silenced: false,
      },
    ];

    for (let i = receivedData.length - 1; i >= 0; i--) {
      let alarm = receivedData[i];
      readAlarmsList.push({
        text: alarm.info.type 
        // + ": " + alarm.headline 
        || "",
        id: alarm.id,
        index: i,
        silenced: alarm.silenced,
      });
    }

    const speakList = [];
    readAlarmsList.forEach((item) => {
      if (!item.silenced && item.source !== "Overwatch AI") {
        speakList.push({ text: item.text, repeat: true });
      } 
      else if (!item.silenced && item.source == "Overwatch AI") {
        speakList.push({ text: item.text, repeat: false });
      } 
    });

    if (speakList.length > 1) {
      tts.beginNewQueue(speakList, false);
    }
    if (speakList.length < 2) {
      tts.cancel();
    }
  };

  useEffect(() => {
    runAlarms2();
  }, [receivedData]);

  return null;
}
