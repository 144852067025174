import { ColorContext } from "App";
import { AlarmsContentWrapper, AlarmContentRow } from "./Components";
import { useAlarms } from "hooks";
import useDebounce from "hooks/useDebounce";
import { usePerms } from "hooks/usePerms";
import React, { useEffect, useState } from "react";

export default function AlarmHistory() {
  const alarms = useAlarms();

  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 1000);

  const [limit, setLimit] = useState(alarms.LIMIT_OPTIONS[0]); //default to the first (lowest) limit option
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    console.log("Calling fetchAlarms");
    //get alarms again when search criteria changes
    alarms.fetchAlarms(limit, pageIndex * limit, searchDebounce);
  }, [pageIndex]);

  useEffect(() => {
    //specifically when search debounce changes, so I can reset the page index
    setPageIndex(0);
    alarms.fetchAlarms(limit, 0, searchDebounce);
  }, [searchDebounce, limit]);

  return (
    <AlarmsContentWrapper
      title='Alarm History'
      pageIndex={pageIndex}
      maxPageIndex={Math.floor(alarms.totalCount / limit) || 1}
      headerFields={[
        "Current State",
        "Source",
        "Device",
        "Type",
        "Activity",
        "Status",
        "",
      ]}
      onPageChanged={(_page) => {
        setPageIndex(_page);
      }}
      onLimitChanged={(_limit) => {
        setLimit(_limit);
      }}
      onSearchChanged={(_search) => {
        setSearch(_search);
      }}
    >
      {alarms.alarms
        ?.filter((alarm, index) => index < limit)
        .map((alarm, index) => (
          <AlarmContentRow key={alarm.id} alarm={{ ...alarm, index: index }} />
        ))}
    </AlarmsContentWrapper>
  );
}
