import { useEffect, useState } from "react";
import * as api from "apis/FusionAPI";

export const LIMIT_OPTIONS = [25, 50, 100];

/**
 * Use alarm data fetched from fusion api.
 * @param {number} [limit] The initial limit to query.
 * @returns {{totalCount: number, alarms: {ackowledged: *[], activity: string, ca_identifier: string, cw_identifier: string, device: string, expire_time: string, ga_identifier: string, headline: string, id: number, info: Object, message: string, organization_id: number, silenced: boolean, silenced_by: (string | number | null), source: string, state: boolean}[], fetchAlarms: (limit: number, offset: number, search: string) => void, LIMIT_OPTIONS: number[]}}
 */
export default function useAlarms(limit = LIMIT_OPTIONS[0]) {
  const [totalCount, setTotalCount] = useState(0);
  const [alarms, setAlarms] = useState();

  useEffect(() => {
    //get alarms
    const fetch = () =>
      FetchAlarmHistory(limit, 0)
        .then((res) => {
          console.log("Response from server in useAlarms:", res);
          setAlarms(res.data.data);
          setTotalCount(res.data.totalCount);
        })
        .catch((error) => {
          console.error(
            "Error occured when fetching alarm history, trying again in 5s:",
            error
          );

          //try to fetch again
          setTimeout(fetch, 5000);
        });

    fetch();
  }, []);

  return {
    totalCount: totalCount,
    alarms: alarms,
    //calls setAlarms with results from server
    fetchAlarms: (limit, offset, search) =>
      FetchAlarmHistory(limit, offset, search)
        .then((res) => {
          console.log("fetchAlarms returned with:", res);
          setAlarms(res.data.data);
          setTotalCount(res.data.totalCount);
        })
        .catch((error) =>
          console.error("Error in useAlarms.fetchAlarmData:", error)
        ),
    LIMIT_OPTIONS: LIMIT_OPTIONS,
  };
}

async function FetchAlarmHistory(limit, offset, search) {
  const res = await api.getAlarmHistoryPaginated(limit, offset, search);
  return res;
}
