import React, { useContext, useEffect, useRef, useState } from "react";
import { BsCheck, BsChevronUp } from "react-icons/bs";
import { FaEllipsis } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import Popup from "reactjs-popup";
import * as api from "apis/FusionAPI";

import aware from "assets/icons/AWAREICON.png";
import default1 from "assets/icons/Default System Icon 1.png";
import default2 from "assets/icons/Default System Icon 2.png";
import background from "assets/icons/Default System Icon Background.png";
import Cookies from "js-cookie";
import useTextToSpeech from "hooks/useTextToSpeech";
import { FaBellSlash, FaCheck } from "react-icons/fa";
import { splitByCharacter } from "utils/stringUtils";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import { Slider } from "antd";
import CheckboxCircle from "./UI/CheckboxCircle";
import { ColorContext } from "App";

const sourceColorMap = {
  "global-actions": "red",
  "custom-weather": "green",
  AWARE: "blue",
  Other: "orange",
};

const iconMap = {
  AWARE: aware,
  "Fusion User": default1,
  "global-actions": default1,
  "Overwatch AI": default2,
  Other: background,
};

const typeMap = {
  "global-actions": "Global Action",
};

const fieldMap = {
  type: "Alert Type",
  timeZone: "Time Zone",
  startTime: "Start Time",
  severity: "Severity",
  NWSOffice: "Issuing Office",
  expireTime: "Expiration Time",
  popAffected: "Population Affected",
  location: "Location",
  alarm_id: "Alarm ID",
};

export default function AlarmListItem({
  style,
  data,
  selectedItems,
  setSelectedItems,
  listOfSilences,
  setAlarmData,
}) {
  const ref = useRef();
  const tts = useTextToSpeech();

  const [dropDown, setDropDown] = useState(false);
  const [modal, setModal] = useState(false);
  const [isSilenced, setIsSilenced] = useState(data.silenced);
  const [fixedData, setFixedData] = useState(data);
  useEffect(() => {
    const newData = {
      ...data,
      info: {
        ...data.info,
        identifier_type: data.cw_identifier
          ? "CW_"
          : data.ca_identifier
          ? "CA_"
          : data.ga_identifier
          ? "GA_"
          : "ID_",
        alarm_id:
          data.cw_identifier || data.ca_identifier || data.ga_identifier,
      },
      silenced: data.silenced,
    };
    setIsSilenced(data.silenced);
    setFixedData(newData);
  }, [data]);

  const onAcknowledge = () => {
    api.acknowledgeAlarm(data.id).then((res) => {
      if (res.status === 200) {
        api.silenceAlarm(data.id).then((res) => {
          if (res.status === 200) {
            setAlarmData((prevData) => {
              const newData = [...prevData];
              const index = newData.findIndex((alarm) => alarm.id === data.id);
              if (index !== -1) {
                newData[index] = { ...newData[index], silenced: true };
                return newData;
              }
              return prevData;
            });
          }
        });
      }
    });

    onClose();
  };

  const theme = useContext(ColorContext);

  const onSilence2 = () => {
    api.silenceAlarm(data.id).then((res) => {
      if (res.status === 200) {
        setAlarmData((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex((alarm) => alarm.id === data.id);
          if (index !== -1) {
            newData[index] = { ...newData[index], silenced: !isSilenced };
            return newData;
          }
          return prevData;
        });
        // setIsSilenced(!isSilenced);
      }
    });
    onClose();
  };

  const onClose = () => {
    setModal(false);
    ref.current.close();
  };

  const getColorCode = (type) => {
    if (sourceColorMap[type]) return sourceColorMap[type];
    else return sourceColorMap["Other"];
  };

  const openStyle = {
    borderTop: "solid grey 1px",
    borderBottom: "solid grey 1px",
    paddingTop: 15,
    paddingBottom: 15,
    cursor: "pointer",
    userSelect: "none",
    backgroundColor: theme.primaryHighlight,
  };

  const closedStyle = {
    borderTop: "solid grey 1px",
    borderBottom: "solid grey 1px",
    paddingTop: 15,
    paddingBottom: 15,
    cursor: "pointer",
    userSelect: "none",
    ...style,
  };

  return (
    <div
      style={dropDown ? openStyle : closedStyle}
      onClick={(e) => {
        e.stopPropagation();
        setDropDown(!dropDown);
      }}
    >
      {/* main info */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            marginLeft: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            gap: 10,
            alignContent: "center",
            alignItems: "center",
          }}
        >
          {data.state ? (
            <CheckboxCircle
              value={selectedItems[data.id]}
              callback={(e) => {
                e.stopPropagation(); // Prevent triggering any parent onClick events
                // Toggle the checkbox state
                setSelectedItems({
                  ...selectedItems,
                  [data.id]: !selectedItems[data.id],
                });
              }}
            />
          ) : (
            <div></div>
          )}
          {data.state === 1
            ? "Active"
            : data.state === 0
            ? "Inactive"
            : "Silenced"}
          {data.state === 1 && isSilenced ? (
            <div
              style={{
                opacity: 0.5,
                marginTop: 3,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onSilence2();
              }}
            >
              <FaBellSlash />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: theme.primaryShadow,
              width: 40,
              height: 40,
              borderRadius: 50,
              border: "solid white 2px",
              marginRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {iconMap[data.source] && (
              <img
                src={iconMap[data.source]}
                style={{ width: 25, height: 25 }}
              />
            )}
          </div>
          <div>{typeMap[data.source] || data.source}</div>
        </div>
        <div style={{ flex: 2 }}>
          <div>{data.device}</div>
          <div>{data.system}</div>
        </div>
        <div style={{ flex: 1 }}>{data.info.type || "No type"}</div>
        <div style={{ flex: 1 }}>
          {new Date(data.activity).toLocaleString()}
        </div>
        <div style={{ flex: 1 }}>{data.status}</div>
        <div style={{ flex: 0.25 }}>{dropDown && <BsChevronUp />}</div>

        <div style={{ flex: 0.25 }}>
          {data.state === 1 && (
            <Popup
              trigger={
                <div
                  style={{
                    display: "flex",
                    width: 30,
                    height: 30,
                    backgroundColor: theme.primary,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 50,
                  }}
                  onClick={() => setModal(true)}
                >
                  <FaEllipsis />
                </div>
              }
              ref={ref}
              open={modal}
              onClose={() => setModal(false)}
              closeOnDocumentClick
            >
              <div
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: 15,
                  userSelect: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "5px 20px 5px 20px",
                    borderBottom: "solid darkblue 1px",
                    cursor: "pointer",
                  }}
                  onClick={() => onAcknowledge()}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      backgroundColor: theme.primary,
                      borderRadius: 50,
                      color: theme.fontColor,
                    }}
                  >
                    <BsCheck />
                  </div>
                  <div>Acknowledge</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "5px 20px 5px 20px",
                    borderBottom: "solid darkblue 1px",
                    cursor: "pointer",
                  }}
                  onClick={() => onSilence2()}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 20,
                      height: 20,
                      marginRight: 10,
                      backgroundColor: theme.primary,
                      borderRadius: 50,
                      color: theme.fontColor,
                    }}
                  >
                    <IoClose />
                  </div>
                  <div>Silence</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "5px 20px 5px 20px",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => onClose()}
                >
                  <div style={{ width: 20, height: 20, marginRight: 10 }} />
                  <div>Close</div>
                </div>
              </div>
            </Popup>
          )}
        </div>
      </div>

      {/* dropdown content */}
      {dropDown && (
        <div
          style={{
            marginTop: 15,
            padding: 15,
          }}
        >
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            {data.headline || data.info.headline || "???"}
          </div>
          {/* <div style={{fontSize: 18, color:'red'}}>Description: {data.description || data.info.description || "???"}</div> */}
          {/* message */}
          <div>
            {splitByCharacter(data.message.trim(), "*").map((line, index) => {
              if (line)
                return (
                  <li key={index} style={{ padding: 2 }}>
                    {line}
                  </li>
                );
            })}
          </div>

          {/* information */}
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: `${Array(
                  Math.floor(Math.sqrt(Object.keys(fixedData.info).length))
                )
                  .fill("1fr")
                  .join(" ")}`,
              }}
            >
              {Object.keys(fixedData.info)
                .sort((a, b) => {
                  //sort order: alert type, start, end, location, lat, lon, issueing office, alarm id
                  const keys = [
                    "type",
                    "startTime",
                    "expireTime",
                    "location",
                    "lat",
                    "lon",
                    "NWSOffice",
                    "alarm_id",
                  ];

                  for (const key of keys) {
                    if (a === key) return -1;
                    if (b === key) return 1;
                  }

                  return a.localeCompare(b);
                })
                .map((key, index) => {
                  if (key === "identifier_type") return;
                  if (key === "alarm_id") {
                    return (
                      <div key={index} style={{ marginTop: 15 }}>{`${
                        fieldMap[key] || key
                      }: ${
                        fixedData.info["identifier_type"] + fixedData.info[key]
                      }`}</div>
                    );
                  }
                  if (key === "severity") {
                    return (
                      <div
                        style={{
                          marginTop: 15,
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          gap: 10,
                        }}
                      >
                        <div
                          key={index}
                          style={{
                            background: alertColorScale(fixedData.info[key]),
                            width: "max-content",
                            padding: 5,
                            borderRadius: 5,
                            alignContent: "center",
                          }}
                        >
                          {`${fieldMap[key] || key}: ${getSeverityLevel(
                            fixedData.info[key]
                          )}`}
                          {/* }: ${getSeverityLevel(data.info[key])}`} ({100 - data.info[key]}%) */}
                        </div>
                        <div
                          style={{
                            background: theme.base,
                            paddingLeft: 5,
                            paddingRight: 5,
                            borderRadius: 5,
                          }}
                        >
                          <Slider
                            min={0}
                            max={100}
                            style={{ width: 200, marginLeft: 10 }}
                            defaultValue={100 - fixedData.info[key]}
                            disabled
                          />
                        </div>
                      </div>
                    );
                  }
                  if (fixedData.info[key])
                    return (
                      <div key={index} style={{ marginTop: 15 }}>{`${
                        fieldMap[key] || key
                      }: ${fixedData.info[key]}`}</div>
                    );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
